import axios from "axios";
import React, {useState, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Container, Row, Col, Form, Button, Card, Image} from "react-bootstrap";
import Swal from 'sweetalert2'
const CustomFormField = ({controlId, label, type, placeholder, name, value, onChange}) => (
    <Form.Group controlId={controlId}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
            type={type}
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
        />
    </Form.Group>
);
const CardFormGroup = ({
                           controlId,
                           label,
                           items,
                           itemName,
                           itemNamePlural,
                           emptyMessage,
                           handleChange,
                           handleAddArrayClick,
                           handleDelete
                       }) => (
    <Card className="m-2 p-4">
        <Form.Group controlId={controlId}>
            <Form.Label>{label}</Form.Label>
            {items.length === 0 ? (
                <Row className="mt-2">{emptyMessage}</Row>
            ) : (
                <>
                    {items.map((item, index) => (
                        <Form.Group controlId={`${itemNamePlural}-${index}`} className="d-flex" key={index}>
                            <div className="flex-fill">

                                <Form.Control
                                    type="text"
                                    placeholder={`Enter ${itemName}`}
                                    name="name"
                                    value={item}
                                    onChange={(event) => handleChange(event,controlId, index)}
                                />
                            </div>
                            <div className="flex-fill">
                                <Button className="mt-2" variant="danger" type="submit" size="sm"
                                        onClick={(event) => handleDelete(event, index, controlId)}>
                                    Delete
                                </Button>
                            </div>
                        </Form.Group>
                    ))}
                </>
            )}

            <Button className="mt-2" variant="primary" type="submit" size="sm" color="purple"
                    onClick={(event) => handleAddArrayClick(event, controlId)}>
                Add More {itemName}
            </Button>
        </Form.Group>
    </Card>
);

const CardPaymentsFormGroup = ({
                                   controlId,
                                   label,
                                   items,
                                   itemName,
                                   itemNamePlural,
                                   emptyMessage,
                                   handleChange,
                                   handleAddArrayClick,
                                   handleDelete
                               }) => (
    <Card className="m-2 p-4">
        <Form.Group controlId={controlId}>
            <Form.Label>{label}</Form.Label>
            {items.length === 0 ? (
                <Row className="mt-2">{emptyMessage}</Row>
            ) : (
                <>
                    {items.map((item, index) => (
                        <Form.Group controlId={`${itemNamePlural}-${index}`} className="d-flex" key={index}>
                            <div className="flex-fill">
                                <Form.Label>{itemName} Method</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={`Enter ${itemName} Method Name`}
                                    name="name"
                                    value={item.name}
                                    onChange={(event) => handleChange(event,controlId, index)}
                                />
                            </div>
                            <div className="flex-fill">
                                <Form.Label>Method Min Limit</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter Min Limit"
                                    name="min"
                                    value={item.min}
                                    onChange={(event) => handleChange(event,controlId, index)}
                                />
                            </div>
                            <div className="flex-fill">
                                <Form.Label>Method Max Limit</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter Max Limit"
                                    name="max"
                                    value={item.max}
                                    onChange={(event) => handleChange(event,controlId, index)}
                                />
                            </div>
                            <div className="flex-fill">
                                <Button className="mt-2" variant="danger" type="submit" size="sm"
                                        onClick={(event) => handleDelete(event, index, controlId)}>
                                    Delete
                                </Button>
                            </div>
                        </Form.Group>
                    ))}
                </>
            )}

            <Button className="mt-2" variant="primary" type="submit" size="sm" color="purple"
                    onClick={(event) => handleAddArrayClick(event, controlId)}>
                Add More {itemName} Method
            </Button>
        </Form.Group>
    </Card>
);



function AddOrUpdate() {
    const navigate = useNavigate();
    const {casinoId} = useParams(); // If using React Router to pass the casinoId parameter for update
    const [Casino, setCasino] = useState({
        index: "",
        isActive:false,
        name: "",
        description: [],
        points:[],
        image:"",
        heading: "",
        review: [],
        stars: "",
        owner: "",
        daily: "",
        monthly: "",
        casinoLink: "",
        url: "",
        established: "",
        currency:"",
        payment: [],
        authorities: [],
        games: [],
        gameProviders: [],
        positives: [],
        negatives: [],
        bonus: [],
        depositMethods: [],
        withdrawalMethods: [],
        testimonials: [],
    });

    const handleAddArrayClick = (event, methodType) => {
        event.preventDefault();

        const config = {
            description:"",
            review:"",
            payment: "",
            bonus: "",
            points:"",
            currency : "",
            authorities: "",
            games: "",
            gameProviders: "",
            positives: "",
            negatives: "",
            withdrawalMethods: {
                name: "",
                min: 0,
                max: 0,
            },
            depositMethods: {
                name: "",
                min: 0,
                max: 0,
            },
            testimonials: {
                name: "",
                review: "",
                stars: 0,
            },
        };

        if (!(methodType in config)) {
            return;
        }

        const newMethod = config[methodType];

        setCasino((prevCasino) => ({
            ...prevCasino,
            [methodType]: [...prevCasino[methodType], newMethod],
        }));
    };


    const handleDelete = (event, index, arrayName) => {
        event.preventDefault();
        setCasino((prevCasino) => ({
            ...prevCasino,
            [arrayName]: prevCasino[arrayName].filter((method, currentIndex) => currentIndex !== index),
        }));
    };

    const [isUpdate, setIsUpdate] = useState(false); // New state to indicate update mode

    const handleFileChange = (event) => {
        const imageFile = event.target.files[0];
        setCasino((prevData) => ({
            ...prevData,
            image: imageFile,
        }));
    };
    const handleChange = (event, controlId, index) => {
        const { name, value } = event.target;

        let updatedCasino;

        switch (controlId) {
            case "depositMethods":
            case "withdrawalMethods":
            case "testimonials":
                updatedCasino = {
                    ...Casino,
                    [controlId]: Casino[controlId].map((item, i) =>
                        i === index ? { ...item, [name]: value } : item
                    ),
                };
                break;

            case "payment":
            case "bonus":
            case "points":
            case "authorities":
            case "games":
            case "positives":
            case "description":
            case "review":
            case "negatives":
            case "gameProviders":
            case "currency" :
                updatedCasino = {
                    ...Casino,
                    [controlId]: [...Casino[controlId]],
                };
                updatedCasino[controlId][index] = value;
                break;
            default:
                updatedCasino = { ...Casino, [name]: value };
        }

        setCasino(updatedCasino);
    };


    const handleClick = async (event) => {
        event.preventDefault();
        try {
            const formData = new FormData();
            formData.append("data", JSON.stringify(Casino));
            formData.append("image", Casino.image);

            let url;
            if (isUpdate) {
                url = `${process.env.REACT_APP_API_ENDPOINT}/update/${casinoId}`;
            } else {
                url = `${process.env.REACT_APP_API_ENDPOINT}/add`;
            }

            const response = isUpdate
                ? await axios.put(url, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                : await axios.post(url, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

            if (response.status === 200 || response.status === 201) {
                // Show confirmation dialog
                Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, Save it!"
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Show success message
                        Swal.fire({
                            title: "Casino Saved!",
                            text: "Casino Details have been Saved.",
                            icon: "success"
                        }).then(() => {
                            // Navigate to /casino after user clicks 'OK'
                            navigate("/casino");
                        });
                    }
                });
            }
        } catch (error) {
            // Extract the error message
            const errorMessage = error.response && error.response.data && error.response.data.error
                ? error.response.data.error
                : 'An unexpected error occurred';

            // Log the error message to the console
            console.error(errorMessage);

            // Display the error message using Swal
            Swal.fire({
                title: "Error!",
                text: errorMessage,
                icon: "error",
                confirmButtonText: "OK"
            });
        }
    };



    useEffect(() => {
        if (casinoId) {
            // Fetch the existing casino details and set isUpdate to true
            // Assuming you have an API endpoint to fetch the casino details by ID
            axios.get(`${process.env.REACT_APP_API_ENDPOINT}/casino/id/${casinoId}`)
                .then((response) => {
                    const casinoData = response.data;
                    setCasino(casinoData);
                    setIsUpdate(true);
                })
                .catch((error) => {
                    alert(error.message)
                });
        }
    }, [casinoId]); // Run this effect when casinoId changes (only in update mode)
    useEffect(() => {
        // console.log(Casinos); // This will log the updated state after it's set
    }, [Casino]);
    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col md={6}>
                    <h1 className="text-center">{isUpdate ? "Update Casinos" : "Add New Casinos"}</h1>
                    <Form>
                        <div className="d-flex justify-content-center">
                            <Button
                                variant={Casino.isActive ? 'success' : 'secondary'}
                                onClick={() => handleChange({ target: { name: 'isActive', value: !Casino.isActive } })}
                            >
                                {Casino.isActive ? 'Active' : 'Inactive'}
                            </Button>
                        </div>

                        <CustomFormField controlId="index" label="Casino Index *" type="number"
                                         placeholder="Enter Casino Index" name="index" value={Casino.index}
                                         onChange={handleChange} required/>
                        <CustomFormField controlId="name" label="Casino Name *" type="text"
                                         placeholder="Enter Casino name" name="name" value={Casino.name}
                                         onChange={handleChange}/>
                        <CardFormGroup
                            controlId="description"
                            label="Casino Description"
                            items={Casino.description}
                            itemName="Description"
                            itemNamePlural="description"
                            emptyMessage="No Description Added"
                            handleChange={handleChange}
                            handleAddArrayClick={handleAddArrayClick}
                            handleDelete={handleDelete}
                        />
                        <CardFormGroup
                            controlId="points"
                            label="Casino Points"
                            items={Casino.points}
                            itemName="Points"
                            itemNamePlural="points"
                            emptyMessage="No Points Added"
                            handleChange={handleChange}
                            handleAddArrayClick={handleAddArrayClick}
                            handleDelete={handleDelete}
                        />

                        <Card className="p-3 d-flex justify-content-center align-items-center">
                            <Form.Group className="mb-3">
                                <Form.Label>Upload Casino Picture</Form.Label>
                                <br/>
                                {isUpdate && (
                                    <Image src={`${Casino.image}`} alt={`Casino Image`}
                                           style={{ width: "50%", height: "50%", objectFit: "cover" }}
                                    />
                                )}
                                <Form.Control
                                    type="file"
                                    name="image"
                                    onChange={handleFileChange}
                                />
                            </Form.Group>
                        </Card>
                        <CardFormGroup
                            controlId="review"
                            label="Casino Review"
                            items={Casino.review}
                            itemName="Review"
                            itemNamePlural="review"
                            emptyMessage="No Review Added"
                            handleChange={handleChange}
                            handleAddArrayClick={handleAddArrayClick}
                            handleDelete={handleDelete}
                        />
                        <CustomFormField controlId="Review" label="Casino Review" type="text"
                                         placeholder="Enter Casino's Review" name="review" value={Casino.review}
                                         onChange={handleChange}/>
                        <CustomFormField controlId="heading" label="Casino heading" type="text"
                                         placeholder="Enter Casino Heading" name="heading" value={Casino.heading}
                                         onChange={handleChange}/>
                        <CustomFormField controlId="stars" label="Stars *" type="text" placeholder="Enter Casino Stars"
                                         name="stars" value={Casino.stars} onChange={handleChange}/>
                        <CustomFormField controlId="link" label="Refferal Link *" type="text" placeholder="Enter Casino Link"
                                         name="link" value={Casino.link} onChange={handleChange}/>
                        <CustomFormField controlId="casinoLink" label="Non Refferal Link (Optional)" type="text" placeholder="Enter Orignal Casino Link"
                                         name="casinoLink" value={Casino.casinoLink} onChange={handleChange}/>
                        <CustomFormField controlId="url" label="Go URL *" type="text" placeholder="Enter Go Url"
                                         name="url" value={Casino.url} onChange={handleChange}/>
                        <CustomFormField controlId="owner" label="Owner" type="text" placeholder="Enter Owner Link"
                                         name="owner" value={Casino.owner} onChange={handleChange}/>
                        <CustomFormField controlId="established" label="Established" type="text"
                                         placeholder="Enter Established Year" name="established"
                                         value={Casino.established} onChange={handleChange}/>
                        <CustomFormField controlId="currency" label="Currency" type="text"
                                         placeholder="$" name="currency"
                                         value={Casino.currency || ""} onChange={handleChange}/>
                        <CustomFormField controlId="daily" label="Daily Deposit Limit" type="number"
                                         placeholder="Enter Daily Deposit" name="daily"
                                         value={Casino.daily} onChange={handleChange}/>
                        <CustomFormField controlId="monthly" label="Monthly Deposit Limit" type="number"
                                         placeholder="Enter Monthly Deposit" name="monthly"
                                         value={Casino.monthly} onChange={handleChange}/>

                            <CardFormGroup
                                controlId="payment"
                                label="Payment Methods"
                                items={Casino.payment}
                                itemName="Payment Method"
                                itemNamePlural="payment"
                                emptyMessage="No Payment Methods Added"
                                handleChange={handleChange}
                                handleAddArrayClick={handleAddArrayClick}
                                handleDelete={handleDelete}
                            />

                            <CardFormGroup
                                controlId="authorities"
                                label="Casino Authorities"
                                items={Casino.authorities}
                                itemName="Authority"
                                itemNamePlural="authorities"
                                emptyMessage="No Authorities Added"
                                handleChange={handleChange}
                                handleAddArrayClick={handleAddArrayClick}
                                handleDelete={handleDelete}
                            />

                            <CardFormGroup
                                controlId="games"
                                label="Casino Games"
                                items={Casino.games}
                                itemName="Game"
                                itemNamePlural="games"
                                emptyMessage="No Games Added"
                                handleChange={handleChange}
                                handleAddArrayClick={handleAddArrayClick}
                                handleDelete={handleDelete}
                            />
                        <CardFormGroup
                                controlId="gameProviders"
                                label="Game Providers"
                                items={Casino.gameProviders}
                                itemName="Game Providers"
                                itemNamePlural="gameProviders"
                                emptyMessage="No Games Providers Added"
                                handleChange={handleChange}
                                handleAddArrayClick={handleAddArrayClick}
                                handleDelete={handleDelete}
                            />

                        <CardFormGroup
                            controlId="positives"
                            label="Game positives"
                            items={Casino.positives}
                            itemName="positives"
                            itemNamePlural="positives"
                            emptyMessage="No Positives Added"
                            handleChange={handleChange}
                            handleAddArrayClick={handleAddArrayClick}
                            handleDelete={handleDelete}
                        />

                        <CardFormGroup
                            controlId="negatives"
                            label="Game Negatives"
                            items={Casino.negatives}
                            itemName="negatives"
                            itemNamePlural="negatives"
                            emptyMessage="No Negatives Added"
                            handleChange={handleChange}
                            handleAddArrayClick={handleAddArrayClick}
                            handleDelete={handleDelete}
                        />
                        <CardFormGroup
                            controlId="bonus"
                            label="Casino Bonus"
                            items={Casino.bonus}
                            itemName="bonus"
                            itemNamePlural="bonus"
                            emptyMessage="No Bonus Added"
                            handleChange={handleChange}
                            handleAddArrayClick={handleAddArrayClick}
                            handleDelete={handleDelete}
                        />

                        <CardPaymentsFormGroup
                            controlId="depositMethods"
                            label="Deposit Methods"
                            items={Casino.depositMethods}
                            itemName="Deposit"
                            itemNamePlural="depositMethods"
                            emptyMessage="No Deposit Methods Added"
                            handleChange={handleChange}
                            handleAddArrayClick={handleAddArrayClick}
                            handleDelete={handleDelete}
                        />

                        <CardPaymentsFormGroup
                            controlId="withdrawalMethods"
                            label="Withdrawal Methods"
                            items={Casino.withdrawalMethods}
                            itemName="Withdrawal"
                            itemNamePlural="withdrawalMethods"
                            emptyMessage="No Withdrawal Methods Added"
                            handleChange={handleChange}
                            handleAddArrayClick={handleAddArrayClick}
                            handleDelete={handleDelete}
                        />

                        <Card className="m-2 p-4">
                            <Form.Group controlId="testimonials">
                                <Form.Label>Customer Testimonials</Form.Label>
                                {Casino.testimonials.length === 0 ? (
                                    <Row className="mt-2">No Testimonials Added</Row>
                                ) : (
                                    <>
                                        {Casino.testimonials.map((item, index) => (
                                            <Form.Group controlId={`testimonials-${index}`} key={index} className="d-flex">
                                                <div className="flex-fill">
                                                    <Form.Label>Customer's Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter the Customer's Name"
                                                        name="name"
                                                        value={item.name}
                                                        onChange={(event) => handleChange(event, "testimonials", index)}
                                                    />
                                                </div>
                                                <div className="flex-fill">
                                                    <Form.Label>Customer's Review</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter the Customer's Review"
                                                        name="review"
                                                        value={item.review}
                                                        onChange={(event) => handleChange(event, "testimonials", index)}
                                                    />
                                                </div>
                                                <div className="flex-fill">
                                                    <Form.Label>Customer's Rating</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Enter the Customer's Rating"
                                                        name="rating"
                                                        value={item.rating}
                                                        onChange={(event) => handleChange(event, "testimonials", index)}
                                                    />
                                                </div>
                                                <div className="flex-fill">
                                                    <Button
                                                        className="mt-2"
                                                        variant="danger"
                                                        type="submit"
                                                        size="sm"
                                                        onClick={(event) => handleDelete(event, index, "testimonials")}
                                                    >
                                                        Delete
                                                    </Button>
                                                </div>
                                            </Form.Group>
                                        ))}
                                    </>
                                )}
                                <Button
                                    className="mt-2"
                                    variant="primary"
                                    type="submit"
                                    size="sm"
                                    color="purple"
                                    onClick={(event) => handleAddArrayClick(event, "testimonials")}
                                >
                                    Add More Testimonials
                                </Button>
                            </Form.Group>
                        </Card>


                        <Button className="mt-2 mb-5" variant="primary" type="submit" onClick={handleClick}>
                            {isUpdate ? "Update Casinos" : "Add Casinos"}
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default AddOrUpdate;
