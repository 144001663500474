import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {Container, Row, Table} from "react-bootstrap";
import { FiEdit } from 'react-icons/fi'
import { RiDeleteBin4Line } from 'react-icons/ri'
import { FaEye } from "react-icons/fa6";
import Swal from 'sweetalert2'
function Casino() {
    const [Casino, setCasino] = useState([]);
    useEffect(() => {
        const fetchAllCasino = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/casino`);
                setCasino(res.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchAllCasino();
    }, []);

    const handleDelete = async (id) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (result.isConfirmed) {
            try {
                await axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/delete/` + id);
                await Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                );
                window.location.reload();
            } catch (error) {
                await Swal.fire(
                    'Error!',
                    'An error occurred while deleting the file.',
                    'error'
                );
                console.error('Error deleting the file:', error);
            }
        }
    };


    return (
        <Container>
            <Row className="display-2 text-center mb-5">
                <p>Casinos</p>
            </Row>
            {Casino.length > 0 ?
                <Row>
                    <Table responsive="xl">
                        <thead>
                        <tr>
                            <th>Index</th>
                            <th>Name</th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {Casino.map((item) => (
                            <tr key={item._id}>
                                <td>{item.index}</td>
                                <td>{item.name}</td>
                                <td><Link to={`/show/${item.index}`}><FaEye style={{ color: "blue" }} size={25} /></Link></td>
                                <td>
                                    {/*<Link to={`/update/${item.id}`}><Button size={25} ></Button></Link>*/}
                                    <Link to={`/update/${item.index}`}><FiEdit size={25} /></Link>
                                </td>
                                <td>
                                     <RiDeleteBin4Line style={{ color: "red" }} size={25} onClick={() => handleDelete(item.index)} />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Row>
                :
                <Row className="text-center">
                    <p>No Casinos found.</p>
                </Row>
            }
            <Row className="mt-3 d-flex">
                <div className="text-center">
                    <Link to="/add" className="btn btn-outline-success">Add New Casino</Link>
                </div>
            </Row>
        </Container>
    );
}

export default Casino;