import { createRoot } from "react-dom/client";
import App from "./App.js";
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter } from 'react-router-dom';
import {StrictMode} from "react";
import { GoogleOAuthProvider } from '@react-oauth/google';
const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
    <StrictMode>
            <GoogleOAuthProvider clientId={clientId}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </GoogleOAuthProvider>;
    </StrictMode>
);
