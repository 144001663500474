import React from 'react';
import {Container, Row} from "react-bootstrap";
function Footer() {
    const footerStyle = {
        fontSize: '12px'
    }
  return (
      <div className="footerstyle text-white bg-black fixed-bottom" >
          <Container>
              <Row className="justify-content-md-center mt-0" style={footerStyle}>
                  <p className="text-center my-0">Copyright © 2023 Review7Star </p>
              </Row>
          </Container>
      </div>
  );
}
export default Footer;
