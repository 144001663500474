import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Navbar, Nav } from 'react-bootstrap';

function Header({ isAuthenticated, onLogout }) {
    var location = useLocation();
    var showHomeNav = location.pathname !== '/';
    var showCasinoNav = location.pathname !== '/casino';
  return (
        <Navbar collapseOnSelect expand="lg" bg="black" variant="dark">
            <Container>
                <Navbar.Brand href="/">
                    Review 7 Star
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mx-auto">
                        {showHomeNav && (
                            <Nav.Link className='headerLinks' href="/">
                                Home
                            </Nav.Link>
                        )}
                        {showCasinoNav && (
                            <Nav.Link className='headerLinks' href="/casino">
                                Casinos
                            </Nav.Link>
                        )}
                    </Nav>
                    {isAuthenticated && (
                        <Nav>
                            <Nav.Link className='headerLinks' onClick={onLogout}>
                                Logout
                            </Nav.Link>
                        </Nav>
                    )}
                </Navbar.Collapse>
            </Container>
        </Navbar>
  );
}
export default Header;
