import React, { useState, useEffect } from 'react';
import Header from "./Fixed/Header.js";
import Footer from "./Fixed/Footer.js";
import Router from "./Configuration/Router.js";
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const loginButtonStyles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        marginTop: '20px',
        padding: '20px',
        backgroundColor: '#f0f0f0',
        border: '1px solid #ccc',
        borderRadius: '5px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    };

    useEffect(() => {
        // Check if the user is authenticated in local storage
        const storedAuth = localStorage.getItem('isAuthenticated');
        if (storedAuth === 'true') {
            setIsAuthenticated(true);
        }
    }, []);

    const handleLoginSuccess = (credentialResponse) => {
        console.log(credentialResponse);

        const token = credentialResponse?.credential;

        //Verify and decode the JWT
        try {
            // Check if the email from the JWT matches the allowed email(s)
            const allowedEmails = ["sanjaybishtamazon@gmail.com","review7star@gmail.com","sanjaybisht8999@gmail.com"]; // Replace with your allowed emails
            const decodedToken = jwt_decode(token);
            console.log(decodedToken)
            if (decodedToken && allowedEmails.includes(decodedToken.email)) {
                setIsAuthenticated(true);
                localStorage.setItem('isAuthenticated', 'true');
            } else {
                setErrorMessage('Access denied. Your email is not authorized.');
            }
        } catch (error) {
            console.error('Error decoding JWT:', error);
        }
    };

    const handleLoginError = () => {
        console.log('Login Failed');
    };

    const handleLogout = () => {
        setIsAuthenticated(false);
        // Clear the authentication state from local storage
        localStorage.removeItem('isAuthenticated');
    };

    return (
        <div>
            {isAuthenticated ? (
                <>
                    <Header isAuthenticated={isAuthenticated} onLogout={handleLogout} />
                    <Router />
                    <Footer />
                </>
            ) : (
                <div style={loginButtonStyles}>
                    {errorMessage && (
                        <p style={{ fontSize: '18px', color: 'red', marginBottom: '10px' }}>
                            {errorMessage}
                        </p>
                    )}
                    <p style={{ fontSize: '18px', marginBottom: '10px' }}>
                        Please log in with Google:
                    </p>
                    <GoogleLogin
                        onSuccess={handleLoginSuccess}
                        onError={handleLoginError}
                        useOneTap
                        buttonText="Login with Google"
                        buttonStyle={{
                            backgroundColor: '#4285F4',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '5px',
                            padding: '10px 20px',
                            fontSize: '16px',
                            cursor: 'pointer',
                        }}
                    />
                </div>
            )}
        </div>
    );
}

export default App;
