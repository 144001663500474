import React from "react";
import Home from "../Components/Home.js";
import { Routes, Route } from 'react-router-dom';
import Casino from "../Components/Casino.js";
import AddOrUpdate from "../Components/AddOrUpdate";
import Show from "../Components/Show.js";
function Router(){
    return (
        <>
            <Routes>
                <Route exact path='/' element={<Home/>}/>
                <Route exact path='/casino' element={<Casino/>}/>
                <Route exact path='/add' element={<AddOrUpdate/>}/>
                <Route exact path='/update/:casinoId' element={<AddOrUpdate />} />
                <Route exact path='/show/:casinoId' element={<Show/>} />
            </Routes>
        </>
    );
}
export  default Router;